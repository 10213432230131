<template>
   <div class="body" v-if="render" >
      <div class="smart-wrap">
         <div class="col col-md-6 text" :style="'background:' + palette[1] + ';'">
            <img style="max-width: 250px;" :src="publicOrganizationData.logoColor[0].url" :alt="publicOrganizationData.logoColor[0].url">
            <h4 style="width: 100%;">Welcome Back!</h4>
            <p style="width: 100%;">To keep connected with us please login with your personal info</p>
         </div>
         <div class="col col-md-6 form">
            <h4>Log In</h4>
            <div class="input-wrap col-12">
               <label for="">Email</label>
               <input type="text" placeholder="Email" v-model="data.name" />
            </div>
            <div class="input-wrap col-12">
               <label for="">Password</label>
               <input type="password" placeholder="Password" v-model="data.pass" />
            </div>
            <a href="/register?smlName=forgotPassword" style="width: 100%;">Forgot Password</a>
            <UiButton text="Log In" v-on:click="loginUser(data.name, data.pass)"/>
         </div>
      </div>
   </div>
</template>
<script>
//AWS - GRAPHQL
import { smd_getWebPage, smd_getRecordIndex } from "../../../graphql/queries";
import { onNotify, onUpdateSmartRecord } from '../../../graphql/subscriptions'
//Tools
import Vuex from "vuex";
import { Auth, API, Storage } from "aws-amplify";
import "amazon-connect-streams";

import Swal from 'sweetalert2'

import { loading, popUp, actionSidebar } from "../../../store/tools";

//Components
import PaperDisplayer from "../../../components/papers/PaperDisplayer.vue";
import PaperLayout from "../../../components/papers/PaperLayout.vue";


import UiButton from "../../../components/ui/UiButton.vue"

export default {
   components: {
      PaperDisplayer,
      PaperLayout,
      UiButton
   },
   data() {
      return {
         data: {},
         palette: [],
         render: false
      };
   },
   created() {
      this.setUserWeb()
   },
   methods: {
      ...Vuex.mapMutations(["setIdentityId","SetOrganizationID", "SetUser", "SetRows", "setDataUser",'setAttUser', 'setPublicOrganization','setUserPermissions' ,'setPublicOrganizationData','SetOrganizationData','setClasses','SetPermissions']),
      ...Vuex.mapActions(['getPublicOrganization','getRecordLists','getEntities','getProducts']),
      
      //Init
      async setUserWeb() {
         console.log('>>> Usuario: ' + this.user);
         loading('show')
         Auth.signIn('smd_webuser', '383@JDU45ss#')
            .then((user) => {
               //Clear Global Variables
               this.SetOrganizationID("Not authorized")
               this.SetOrganizationData("Not authorized")
               this.setPublicOrganization(undefined)
               this.setPublicOrganizationData(undefined)
               //console.clear()
               ///-----

               this.getPublicOrganization()
               setTimeout(() => {
                  this.render= true
                  loading('hidden')
               }, 1200);
            })
            .catch((err) => console.log(err))
      },
      async loginUser(username, pass) {
         let inputUser = username
         this.apiRequest = true;
         username = this.publicOrganization.replace('ORG#', '').toLowerCase() + '.' + username
         console.log(username, pass);
         Auth.signIn(username, pass)
            .then((user) => {
               console.clear()
               console.log("%cThe BizplanEasy team welcomes you!!!", "font-size:20px;");
               console.log(user);
               this.SetOrganizationID(user.attributes["custom:organizationID"]);
               //this.getOrganizationData(user.attributes["custom:organizationID"]) //Before Integrate Alias
               this.SetUser(user.username);
               this.getEntities()
               this.setUserPermissions(user.signInUserSession.accessToken.payload['cognito:groups'])
               this.apiRequest = false;
               if (user.attributes["custom:organizationID"] == this.publicOrganization || window.location.host.replace('www.', '').toLowerCase() == 'localhost:8080' || window.location.host.replace('www.', '').toLowerCase() == '192.168.1.2:8080' || window.location.host.replace('www.', '').toLowerCase() == 'test.bizplaneasy.com') {
                  this.setAttUser(user.attributes)
                  console.log(this.publicOrganizationData.app);
                  let path = '/'+this.publicOrganizationData.app+'-'+this.urlHistory
                  if (this.urlHistory == '') {
                     path = '/'+this.publicOrganizationData.app+'-'+user.signInUserSession.accessToken.payload['cognito:groups'][0]
                  }
                  // Peding for check directo to login <<<
                  setTimeout(() => {
                     this.$router.push({
                        path: path,
                     });
                  }, 1000);

               } else {
                  console.log(user.username.toUpperCase() + ' of ' + user.attributes["custom:organizationID"] + ' - Alias: ' + this.publicOrganization);
                  Swal.fire({
                     icon: 'error',
                     title: 'Unauthorized User',
                     text: 'Contact your provider',
                     //footer: 'f_GetItem = smd_getSMLItem PK:  '+ pk + ', SK: ' +sk,
                  })
               }
            })
            .catch((err) => {
               Swal.fire({
                  icon: 'error',
                  title: 'Something went wrong',
                  text: err,
                  //footer: 'f_GetItem = smd_getSMLItem PK:  '+ pk + ', SK: ' +sk,
               })
               console.log(err)
            });
      },
   },
   computed: {
      ...Vuex.mapState(["organizationID",'organizationData', 'publicOrganization','user','publicOrganization', 'publicOrganizationData','currentItem','userPermissions','urlHistory'])
   },
   watch: { },
};

</script>
<style lang="less">
.body {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: nowrap;
   min-height: 100vh;
   min-width: 100vw;
}

.col {
   padding: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   text-align: center;
   align-content: center;
}
.smart-wrap {
   width: 800px;
   background: #fff;

}
@media (max-width: 992px) {

}
@media (max-width: 767px) {
   .col {
      width: 100%;
   }
   .body {
      padding: 20px;
      min-width: none;
   }
   .smart-wrap{
      overflow: hidden;
      display: block;
   }
}
@media (max-width: 520px) {

}
</style>